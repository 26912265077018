<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('analytic.lang_auswertung') "
                :icon="icon"
                :subheading="$t('analytic.lang_auswertung')"
        ></page-title>
        <div class="app-main__inner">
            <time-track-analyse-component/>
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import TimeTrackAnalyseComponent from "../../../components/timesheet/analyses/TimeTrackAnalyseComponent";


    export default {
        components: {
            TimeTrackAnalyseComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-graph3 icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>