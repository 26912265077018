<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="6" outlined>

      <v-card-text class="ma-0 pa-0">


        <v-card elevation="0">

          <v-card-title class="pa-0 ma-0">
            <v-row>

              <v-col class="pt-2 ma-0" cols="12">
                <div class="card-header-tab card-header">
                  <div class="card-header-title font-size-lg  pt-8 pr-2 text-capitalize font-weight-normal">
                    <v-row class="">
                      <v-col cols="12" md="4" sm="12">
                        <b-input-group width="100%">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                        :placeholder="$t('datatables.lang_search')"
                                        @focus="showTouchKeyboard"
                                        dense
                                        outlined
                                        prepend-inner-icon="search"
                                        v-model="search"/>
                        </b-input-group>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <b-input-group width="100%">
                          <birthdaypicker dense v-bind:current-date="this.start"
                                          v-model="start"/>

                          <!--<date-picker confirm
                                       format="[on] YYYY-DD-MM [at] HH:mm" lang="en"
                                       style="width: 100% !important"
                                       type="datetime" v-model="start"
                                       value-type="timestamp"></date-picker>-->
                        </b-input-group>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <b-input-group width="100%">
                          <birthdaypicker dense
                                          v-bind:current-date="this.end"
                                          v-bind:min="this.start"
                                          v-model="end"/>

                          <!--<date-picker format="[on] YYYY-DD-MM [at] HH:mm"
                                       lang="en" style="width: 100% !important"
                                       type="datetime"
                                       v-model="end" value-type="timestamp"></date-picker>-->
                        </b-input-group>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="btn-actions-pane-right actions-icon-btn">
                    <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
                                    <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i>
                                    </span>
                      <div>
                        <h6 class="dropdown-header" tabindex="-1">
                          {{ $t('generic.lang_Options') }}</h6>
                        <button @click="generatePdfAll" class="dropdown-item" tabindex="0"
                                type="button"
                        >
                          <v-icon class="dropdown-icon" color="warning">picture_as_pdf
                          </v-icon>
                          <span>PDF</span>
                        </button>

                        <button class="dropdown-item"
                                tabindex="0" type="button">
                          <font-awesome-icon class="dropdown-icon success--text"
                                             icon="file-excel"/>
                          <span>Excel</span></button>
                      </div>
                    </b-dropdown>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="ma-0 pa-0"/>
          <v-data-table
              :footer-props="footerProps"
              :headers="datatableHeaders"
              :items="dataToShow"
              :loading="loading"
              :options.sync="pagination"
              :server-items-length="total"
              :show-select="true"
              footer-props.items-per-page-text="Einträge pro Seite"
              item-key="userID"
              v-model="selectedRows"
          >

            <template v-slot:item.userID="{ item }">

                            <span :style="{
                                color: getRandomColor()
                            }" class="text-muted text-center">#{{ item.userID }}</span>

            </template>


            <template v-slot:item.overtime="{ item }">

              <v-chip

                  :class="getColor(item)"
                  dot
              >
                {{ secondsToTime(item.overtime).h + 'h:' + secondsToTime(item.overtime).m + 'm' }}
              </v-chip>

            </template>


            <template v-slot:item.name="{ item }">

              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left mr-3">
                    <v-avatar class="widget-content-left primary" size="40">
                      <img :src="item.avatarURI" alt="" class="rounded-circle"
                      >
                    </v-avatar>
                  </div>
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">{{ item.preName + " " + item.lastName }}</div>
                    <div class="widget-subheading opacity-7">{{ item.role }}</div>
                  </div>
                </div>
              </div>

            </template>

            <template v-slot:item.action="{ item }">
              <v-btn @click="history(item)" class="ma-1 btn-sm" color="primary" icon
                     type="button">
                <v-icon size="23">info</v-icon>
              </v-btn>

              <v-btn :disabled="loadingPDF" @click="downloadPDF(item)" class="ma-1 btn-sm" color="warning" icon
                     type="button">
                <v-icon size="23">picture_as_pdf</v-icon>
              </v-btn>
              <v-btn @click="downloadExcel(item)" class="ma-1 btn-sm" color="success" icon
                     type="button">
                <font-awesome-icon class="dropdown-icon" icon="file-excel"
                                   size="lg"/>
              </v-btn>

              <v-btn @click="open_email_dialig(item)" class="ma-1 btn-sm" color="primary" icon
                     type="button">
                <font-awesome-icon class="dropdown-icon" icon="paper-plane"
                                   size="lg"/>
              </v-btn>

            </template>

          </v-data-table>
        </v-card>


        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                              :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"
                              id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>


      </v-card-text>
    </v-card>

    <!--
    time track history
    -->
    <v-dialog max-width="600" v-model="details">
      <v-card class="pa-0 ma-0 elevation-0">
        <history-time-line class="elevation-0" v-bind:pause-time-j-s-o-n="pauseTimeJSON" v-bind:times="times"
                           v-bind:user="user"
                           v-if="this.details"/>

        <v-col class="text-right" cols="12">
          <div class="text-right">
            <v-spacer/>
            <button @click="details = false" class="mx-auto btn btn-danger" color="error">
              {{ this.$t('generic.lang_close') }}
            </button>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <!--
    send email dialog
    -->
    <v-dialog v-model="email_dialog" max-width="600px" persistent>
      <v-form ref="emailForm" lazy-validation>
        <div class="card">
          <div class="card-header">
            {{ $t('generic.lang_email') }}
          </div>

          <div class="card-body">
            <v-row>
              <v-col class="pb-0 mb-0" cols="12">
                <v-text-field outlined @focus="showTouchKeyboard" clearable :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :rules="[v => !!v]" :label="$t('generic.lang_emailAdresse')" dense
                              v-model="form.email"/>
              </v-col>

              <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12">
                <v-text-field v-model="form.subject" :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]"
                              clearable dense :label="$t('settings.lang_mail_subject')" outlined @focus="showTouchKeyboard"/>
              </v-col>

              <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12" sm="12">
                <v-row class="pt-0 mt-0 pb-0 mb-0">
                  <v-col class="pt-0 mt-0 pb-0 mb-0" cols="6">
                    <v-checkbox v-model="form.pdf" :value="true"
                                class="pt-0 mt-0 pb-0 mb-0"
                                :label="$t('generic.lang_attachPdf')"/>
                  </v-col>

                  <v-col class="pt-0 mt-0 pb-0 mb-0" cols="6">
                    <v-checkbox v-model="form.excel" :value="true"
                                class="pt-0 mt-0 pb-0 mb-0"
                                :label="$t('generic.lang_attachExcel')"/>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="pt-0 mt-0" cols="12">
                <quill-editor v-model="form.message" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :options="quillOptions" :rules="[v => !!v]" @focus="showTouchKeyboard"></quill-editor>
              </v-col>

            </v-row>
          </div>

          <div class="card-footer text-right pa-1 pr-3">
            <v-spacer/>
            <v-btn class="ma-1" color="error" @click="email_dialog = false">
              {{ $t('generic.lang_cancel') }}
            </v-btn>
            <v-btn :disabled="sendLoader" :loading="sendLoader" class="ma-1" color="primary" @click="send">
              {{$t('support.lang_supportSend')}}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>

  </v-container>
</template>


<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel, faInfo, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import {faBarcodeRead} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Events} from "../../../plugins/events";
import {ENDPOINTS} from "../../../config";
import {mapState} from "vuex";
import mixin from "@/mixins/KeyboardMixIns";

import VueHtml2pdf from 'vue-html2pdf'
import Birthdaypicker from "../../common/birthdaypicker";
import DatePicker from 'vue2-datepicker'
import HistoryTimeLine from "./HistoryTimeLine";


import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

library.add(
    faFileExcel, faBarcodeRead, faPaperPlane, faInfo
);

export default {
  name: "TimeTrackAnalyseComponent",

  components: {
    HistoryTimeLine,
    Birthdaypicker,
    DatePicker,
    'font-awesome-icon': FontAwesomeIcon,
    'vue-html2pdf': VueHtml2pdf,
    quillEditor
  },

  mixins: [mixin],

  data() {
    return {
      loadingPDF: false,
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },
      form: {
        email: "",
        subject: "",
        message: "",
        excel: false,
        pdf: false,
        userId: ''
      },
      searchEmail: "",
      sendLoader: false,
      times: null,
      details: false,
      start: "",
      end: "",
      ENDPOINTS,
      excelFileName: "Price template table",
      dataToShow: [],
      search: '',
      awaitingSearch: null,
      total: 0,
      loading: false,
      selectedRows: [],
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      avtivateAll: false,
      disavtivateAll: false,
      hover: false,
      fab: false,
      loadingDialog: false,
      user: null,
      pauseTimeJSON: null,
      selected_user: null,
      email_dialog: false
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('customers.lang_cust_name'),
          field: "name",
        },
        {
          label: this.$t('timetrack.lang_workingTime'),
          field: "worktime",
        },
        {
          label: this.$t('timetrack.lang_netWorkingTime'),
          field: "worktimeNet",
        },
        {
          label: this.$t('timetrack.lang_overTime'),
          field: "overtime",
        },
      ];
    },
    datatableHeaders: function () {
      return [
        {
          text: "#" + this.$t('settings.lang_userid'),
          value: "userID",
          sortable: true,
        },
        {
          text: this.$t('customers.lang_cust_name'),
          value: "name",
          sortable: false
        },
        {
          text: this.$t('timetrack.lang_workingTime'),
          value: "worktime",
          align: "center",
        },
        {
          text: this.$t('timetrack.lang_netWorkingTime'),
          value: "worktimeNet",
          align: "center",
        },
        {
          text: this.$t('timetrack.lang_overTime'),
          value: "overtime",
          align: "center",
        },
        {
          text: this.$t('generic.lang_action'),
          value: "action",
          align: "center",
        },
      ];
    },
  },

  mounted() {
    let current = new Date();
    current.setDate(1);
    this.start = this.dateFormater(current);
    this.end = this.dateFormater(this.lastDay(new Date()));
    //console.log(this.user)
  },

  watch: {

    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    start(val) {
      if (this.end && (this.dateToUnix(new Date(val)) >= this.dateToUnix(new Date(this.end))))
        this.end = this.dateFormater(this.lastDay(new Date(this.start)))
      if (this.start && this.end)
        this.getDataFromApi();
    },
    end(val) {
      if (this.start && this.end)
        val && this.getDataFromApi();
    }
  },

  methods: {
    send() {
      if (!this.$refs.emailForm.validate()) return;
      this.sendLoader = true;
      let data = {};
      Object.assign(data, this.form);
      data.emails = [this.form.email]
      data.userId = this.selected_user.userID;
      data.reportStart = this.dateToUnix(new Date(this.start));
      data.reportEnd = this.dateToUnix(new Date(this.end));
      this.axios.post(ENDPOINTS.TIMESHEET.ANALITICS.SENDEMAIL, data).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });

          this.email_dialog = false;
          this.restEmailForm();
          this.$refs.emailForm.reset();
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.sendLoader = false;
      })
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    },
    restEmailForm() {
      this.form.pdf = false;
      this.form.excel = false;
      this.form.message = "";
      this.form.subject = "";
      this.form.email = "";
      // this.$refs['emailForm'].reset();
    },
    open_email_dialig(user) {
      this.restEmailForm();
      this.selected_user = user;
      this.form.userId = user.id
      this.form.email = user.email;
      this.form.subject = this.$t('timetrack.lang_yourTimeTrackingRecordsBetween') + ": " + this.start + ' ' + this.$t('generic.lang_and') + ' ' + this.end
      this.form.pdf = true;
      this.email_dialog = true;
    },
    history(item) {
      this.loadingDialog = true;
      this.details = true;
      this.axios.post(ENDPOINTS.TIMESHEET.ANALITICS.HISTORY, {
        startTimeStamp: new Date(this.start).getTime() / 1000,
        endTimeStamp: new Date(this.end).getTime() / 1000,
        userID: item.userID
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.user = res.data.data.user;
          this.details = true;
          this.pauseTimeJSON = res.data.data.history;
          this.times = res.data.data.times;
        } else if (res.data.STATUS === "ERROR") {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadingDialog = false;
      })
    },
    generatePdfAll() {

    },
    secondsToTime(secs) { //this function convert incoming times from DB as seconds to human time
      var hours = Math.floor(secs / (60 * 60));

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);

      var obj = {
        "h": hours < 10 ? '0' + hours : hours,
        "m": minutes < 10 ? '0' + minutes : minutes,
        "s": seconds < 10 ? '0' + seconds : seconds
      };
      return obj;
    },
    timeToSeconds(time) {
      let arr = time.split(':');

      if (arr.length == 3)
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60) + parseInt(arr[2]);
      else if (arr.length == 2)
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60);
      else if (arr.length == 1)
        return (parseInt(arr[0]) * 3600);
    },
    getColor(item) {

      let the10 = (15 * this.timeToSeconds(item.worktimeNet)) / 100,
          the20 = (15 * this.timeToSeconds(item.worktimeNet)) / 100;

      if (parseInt(item.overtime) <= the10)
        return 'success';
      else if (parseInt(item.overtime) > the10 && parseInt(item.overtime) <= the20)
        return "wrong"
      else return "error";
    },
    dateFormater(date) {
      let day = parseInt(date.getDate()) < 10 ? '0' + date.getDate() : date.getDate(),
          month = (parseInt(date.getMonth()) + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
          year = parseInt(date.getFullYear());
      return year + '-' + month + '-' + day
    },
    dateToUnix(date) {
      return Math.round(+date.getTime() / 1000);
    },
    downloadExcel: function (item) {
      let url = this.axios.defaults.baseURL + "/" + ENDPOINTS.TIMESHEET.ANALITICS.EXCELSINGEL + "?startTimeStamp=" + this.dateToUnix(new Date(this.start)) + "&endTimeStamp=" + this.dateToUnix(new Date(this.end)) + "&userID=" + item.userID;

      url += "&apiToken=" + this.axios.defaults.headers.common['3POSAPITOKEN'] + "&bs=" + this.axios.defaults.headers.common['3POSBS'];
      url += "&bsid=" + this.axios.defaults.headers.common['3POSBSID'];

      let a = document.createElement('a');
      a.setAttribute('target', '_blank');
      a.href = url;
      a.click();
      a.remove();
    },
    downloadPDF: function (item) {
      /*      let url = this.axios.defaults.baseURL + "/" + ENDPOINTS.TIMESHEET.ANALITICS.PDFSINGL + "?reportStart=" + this.dateToUnix(new Date(this.start)) + "&reportEnd=" + this.dateToUnix(new Date(this.end)) + "&userId=" + item.userID;

            url += "&apiToken=" + this.axios.defaults.headers.common['3POSAPITOKEN'] + "&bs=" + this.axios.defaults.headers.common['3POSBS'];
            url += "&bsid=" + this.axios.defaults.headers.common['3POSBSID'];

            let a = document.createElement('a');
            a.setAttribute('target', '_blank');
            a.href = url;
            a.click();
            a.remove();*/

      this.loadingPDF = true;
      this.axios.post(ENDPOINTS.TIMESHEET.ANALITICS.PDFSINGL, {
            reportStart: this.dateToUnix(new Date(this.start)),
            reportEnd: this.dateToUnix(new Date(this.end)),
            userId: item.userID
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        /*        let a = document.createElement('a');
                a.setAttribute('target', '_blank');
                a.href = url;
                a.click();
                a.remove();
                this.iframePDFContent = URL.createObjectURL(blobContent);
                this.displayInvoiceDialog = true;*/
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), item.userID + ".pdf");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingPDF = false;
      })
    },
    lastDay(date) {
      let dt = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
      return dt;
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      if (this.start && this.end) {
        params.startTimeStamp = Math.round(+new Date(this.start).getTime() / 1000);
        params.endTimeStamp = Math.round(+new Date(this.end).getTime() / 1000);
      }

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);

        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }


      this.axios.get(ENDPOINTS.TIMESHEET.ANALITICS.USERS, {
        params: params
      }).then((res) => {
        //console.log(res)
        this.total = parseInt(res.data.iTotalDisplayRecords);
        this.dataToShow = res.data;

        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

        this.dataToShow = [];
      });
    }
  }
}
</script>

<style scoped>
.card-header.card-header-tab .card-header-title {
  width: 100%;
}

#create .v-speed-dial {
  position: fixed !important;
  right: 0;
  bottom: 0;
  border: 2px solid red !important;
}

#create .v-btn--floating {
  position: relative;
}

.rouded-btn {
  position: fixed !important;
  z-index: 1000;
  bottom: 0;
  right: 0;
}

input {
  background-color: white !important;
}

v-dialog:not(.v-dialog--fullscreen) {
  box-shadow: none !important;
}

</style>
